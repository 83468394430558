<template>
  <div
    class="preview dark"
    v-loading="loading"
    element-loading-text="加载中..."
    :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="preview-header">
      <div class="breadcrumb-head">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item
            ><span style="cursor: pointer;color: #1eaaff;"
              >市场审批</span
            ></el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
    </div>
    <div class="preview-body">
      <el-scrollbar>
        <div class="frame common-frame">
          <div class="line" style="padding:24px 0 16px">
            <div class="left" style="display: flex;">
              <el-input v-model="conf.prjName" placeholder="请输入项目名称" />
              <el-input v-model="conf.no" placeholder="请输入版本号" style="margin-left:10px;"/>
              <el-select v-model="conf.statusName" placeholder="请选择状态" @change="selectStatus" style="margin-left:10px;width:300px;">
                <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <el-button
              type="primary"
              style="margin-left:10px;"
              @click="getDataFn"
              >搜索</el-button>

            <div class="right">
              <div class="insert">
                <div class="attr colon">自动审批</div>
                <div class="val">
                  <el-radio-group v-model="record.autoApprove" class="ml-4" @change="val =>
                      setApproveStatus(val)">
                    <el-radio :label="0" size="large">关闭</el-radio>
                    <el-radio :label="1" size="large">全部</el-radio>
                    <el-radio :label="2" size="large">免费</el-radio>
                    <el-radio :label="3" size="large">收费</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>

          </div>
          <el-table :data="tableData" style="width: 100%">
            <el-table-column label="封面" prop="coverPic" min-width="20%">
              <template #default="scope">
                <el-image
                  style="width: 50px; height: 50px; margin-bottom:-4px"
                  :preview-src-list="[scope.row.coverPic]"
                  :src="scope.row.coverPic"
                  :preview-teleported="true"
                >
                </el-image>
              </template>
            </el-table-column>
            <el-table-column
              label="版本名称"
              prop="name"
              min-width="25%"
            >
              <template #default="scope">
                <span style="cursor: pointer;color: #1eaaff;" @click="previewVer(scope.row)">{{scope.row.name}}</span>
              </template>
            </el-table-column>
            <el-table-column label="版本号" prop="verNo" min-width="10%" />
            <el-table-column label="来源项目" prop="prjName" min-width="20%" />
            <el-table-column
              label="审核状态"
              prop="statusName"
              min-width="10%"
            />
            <el-table-column label="操作" min-width="15%">
              <template #default="scope">
                <el-button
                  link
                  size="small"
                  @click="resolveRecord(scope.row)"
                  v-if="scope.row.status != 2"
                >
                  通过
                </el-button>
                <el-button
                  link
                  size="small"
                  @click="showRejRecord(scope.row)"
                  v-if="scope.row.status == 0 || scope.row.status == 3"
                >
                  驳回
                </el-button>
                <el-button
                  link
                  size="small"
                  @click="showAppealRecord(scope.row)"
                  v-if="scope.row.status == 3"
                >
                  回复
                </el-button>
                <el-button link size="small"
                @click="detailShow(scope.row)">
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="line pd-10">
            <page
              :total="conf.total"
              v-show="tableData.length"
              :conf="{
                pageSize: 20,
                pageSizeOption: [10, 20, 50, 100],
                currentPage: conf.pageNum
              }"
              @onChange="onChange"
            ></page>
          </div>
        </div>
      </el-scrollbar>
    </div>

    <window
      windowId="operate-record"
      :shadow="true"
      class="dark"
      :title="record.title"
      @windowHide="recordHide"
      v-show="record.isShow"
    >
      <template #body>
        <div class="line pd-20">
          <div class="insert">
            <div class="attr colon">{{ record.key }}</div>
            <div class="val">
              <el-input
                v-model="record.description"
                placeholder="请输入描述"
                :rows="4"
                type="textarea"
              />
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="line pd-20 align-right">
          <el-button @click="recordHide()">取消</el-button>
          <el-button
            type="primary"
            v-if="record.type == 0"
            @click="rejectRecord()"
            >保存</el-button
          >
          <el-button
            type="primary"
            v-if="record.type == 1"
            @click="appealRecord()"
            >保存</el-button
          >
        </div>
      </template>
    </window>

    <window
      windowId="life-cycle"
      :shadow="true"
      class="dark"
      title="详情"
      @windowHide="detailHide"
      v-show="detail.isShow"
    >
      <template #body>
        <div class="line pd-20">
          <el-timeline class="my-timeline">
            <el-timeline-item
              v-for="(item, index) in detail.list"
              :key="index"
              :color="item.color"
              placement="top"
              :timestamp="item.createDate"
            >
              <el-card>
                <div class="opt-layet">
                  <img :src="item.head" :title="item.createUser"
                         alt="">
                  <h4>{{item.statusName}}</h4>
                  <p>{{item.description}}</p>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </template>
    </window>
  </div>
  <ScreenPreview :prjId="preview.prjId" :obj="preview.screen" :isShare="false" v-if="preview.isShow" />
</template>

<script>
import Window from "components/common/forms/Window";
import Page from "components/common/forms/Page";
import Empty from "components/common/forms/Empty";
import ScreenPreview from 'views/outerChain/screen/screenPreview';
export default {
  name: "CaseManagement",
  components: {
    Window,
    Page,
    Empty,
    ScreenPreview
  },
  data() {
    return {
      loading: true,
      tableData: [],
      conf: {
        prjName: "",
        no: "",
        status: -1,
        statusName: '全部',
        type: 2,
        pageSize: 20,
        pageNum: 1,
        total: 20
      },
      statusList: [{id:-1, name: '全部'},{id: 0, name:'审核中'},{id: 1, name:'驳回'},{id: 2, name:'审核通过'},{id: 3, name:'申诉中'}],
      record: {
        isShow: false,
        autoApprove: 0,
        type: 0,
        currentObj: {},
        title: "",
        key: "",
        description: ""
      },
      detail: {
        isShow: false,
        list: []
      },
      preview: {
        isShow: false,
        prjId: '',
        screen: ''
      }
    };
  },
  mounted() {
    this.getAutoApproveStatus();
    this.getDataFn();
    this.shortcutKey();
  },
  methods: {
    getDataFn() {
      let self = this;
      self.$api.sp.searchPubVerInfo(self.conf).then(res => {
        self.tableData = res.list;
        self.tableData.forEach(e => {
          e.coverPic = e.cover ? "/file/" + e.cover : "";
          e.statusName = self.getStatusName(e.status);
        });
        self.conf.total = res.total;
        self.loading = false;
      });
    },
    onChange(param) {
      this.conf.pageSize = param.pageSize;
      this.conf.pageNum = param.currentPage;
      this.getDataFn();
    },
    getStatusName(status) {
      let result = "";
      switch (status) {
        case 0:
          result = "审核中";
          break;
        case 1:
          result = "驳回";
          break;
        case 2:
          result = "审核通过";
          break;
        case 3:
          result = "申诉中";
          break;
        default:
          result = "全部";
          break;
      }
      return result;
    },
    getStatusColor(status) {
      let result = "";
      switch (status) {
        case 0:
          result = "rgba(30, 170, 255, 1)";
          break;
        case 1:
          result = "rgba(235, 43, 12, 1)";
          break;
        case 2:
          result = "rgba(55, 250, 200, 1)";
          break;
        case 3:
          result = "rgba(250, 115, 0, 1)";
          break;
        default:
          result = "";
          break;
      }
      return result;
    },
    resolveRecord(obj) {
      let self = this;
      self
        .$confirm("是否通过该申请?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          self.$api.sp
            .resolveRecord({
              pubId: obj.pubId,
              inAdmin: 1,
              description: "审批通过"
            })
            .then(data => {
              if (data) {
                self.$message.success("审核已通过!");
                self.getDataFn();
              }
            });
        });
    },
    showRejRecord(obj) {
      this.record.currentObj = { ...obj };
      this.record.title = "驳回";
      this.record.type = 0;
      this.record.key = "驳回描述";
      this.record.description = "";
      this.record.isShow = true;
    },
    rejectRecord() {
      let self = this;
      self.$api.sp
        .rejectRecord({
          pubId: self.record.currentObj.pubId,
          inAdmin: 1,
          description: self.record.description
        })
        .then(data => {
          if (data) {
            self.$message.error("审核已驳回!");
            self.recordHide();
            self.getDataFn();
          }
        });
    },
    showAppealRecord(obj) {
      this.record.currentObj = { ...obj };
      this.record.title = "申诉回复";
      this.record.type = 1;
      this.record.key = "申诉描述";
      this.record.description = "";
      this.record.isShow = true;
    },
    appealRecord() {
      let self = this;
      self.$api.sp
        .appealRecord({
          pubId: self.record.currentObj.pubId,
          inAdmin: 1,
          description: self.record.description
        })
        .then(data => {
          if (data) {
            self.$message.error("申诉已回复!");
            self.recordHide();
            self.getDataFn();
          }
        });
    },
    recordHide() {
      this.record.isShow = false;
    },
    detailShow(obj) {
      let self = this;
      self.$api.sp.selectAllRecords({
        pubId: obj.pubId
      }).then(data => {
        self.detail.list = data;
        self.detail.list.forEach(e => {
          e.head = require('assets/images/frame/default-head.svg');
          e.statusName = self.getStatusName(e.status);
          e.color = self.getStatusColor(e.status);
        })
        self.detail.isShow = true;
      })
    },
    detailHide() {
      this.detail.isShow = false;
    },
    selectStatus(val) {
      this.conf.status = val;
      this.conf.statusName = this.getStatusName(val);
    },
    previewVer(obj) {
      let self = this;
      self.$api.sp.getVerScreen({
        prjId: obj.prjId,
        verId: obj.verId
      }).then((data) => {
        self.preview.isShow = true;
        self.preview.screen = data;
        self.preview.prjId = obj.prjId;
      })
    },
    shortcutKey () {
      let self = this;
      document.addEventListener('keyup', (event) => {
        //esc退出全屏预览
        if (event.which === 27) {
          self.preview.isShow = false;
          event.preventDefault();
        }
      }, false);
    },
    getAutoApproveStatus () {
      let self = this;
      self.$api.sp.getAutoApproveStatus({module: 'market'}).then(data => {
        self.record.autoApprove = data;
      })
    },
    setApproveStatus(type) {
      let self = this;
      self.$api.sp.setAutoApprove({
        module: 'market',
        type: type
      }).then(data => {
        if(type == 0) {
          self.$message.success('关闭成功');
        }else {
          self.$message.success('开启成功');
        }
      })
    }
  }
};
</script>

<style lang="scss">
.opt-layet {
  width: 100%;
  padding: 0 0 0 50px;
  display: inline-block;

  img {
    width: 40px;
    height: 40px;
    background: #ddd;
    border-radius: 50%;
    float: left;
    margin: 0 -50px;
  }

  h4,
  p {
    float: left;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h4 {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    line-height: 1;

    &:hover {
      color: rgba(115, 200, 255, 1);
    }
  }

  p {
    width: 100%;
    color: rgba(255, 255, 255, 0.8);
    cursor: default;
    line-height: 1.4;

    span {
      cursor: pointer;
      color: rgba(30, 170, 255, 1);

      &:hover {
        color: rgba(115, 200, 255, 1);
      }
    }
  }
}
</style>
